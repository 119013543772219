import React from "react";
import { Container, Box, Grid, Typography, Paper } from "@mui/material";
import { useTheme } from "@mui/system";
import vkoverIcon from "./../../images/vkover-icon.png";
import fast from "./../../images/fast.svg";
import storage from "./../../images/storage.svg";
import support from "./../../images/support.svg";
const AdvantageCard = ({ logo, title, description }) => {
  return (
    <>
      <Grid
        item
        sm={12}
        md={4}
        mb={"100px"}
        sx={{
          "@media (max-width: 768px)": {
            mb: "20px",
          },
        }}
      >
        <Paper
          sx={{
            p: 5,
            height: "100%",
            background: "url() no-repeat center",
          }}
        >
          <Grid container alignItems={"center"} rowSpacing={1}>
            <Grid item xs={3}>
              <img src={logo} alt='fast' height={"60px"} width={"60px"} />
            </Grid>
            <Grid item xs={9}>
              <Typography
                variant='subtitle1'
                color='#2D2C5F'
                fontWeight={500}
                fontSize='20px'
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1' fontSize={"15px"}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
const AdvantagesSection = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 4,
          justifyContent: "center",
          gap: "24px",
          mb: "60px",
          "@media (max-width: 768px)": {
            mt: "30px",
            justifyContent: "center",
            px: 1,
            mb: "30px",
          },
        }}
      >
        <img
          src={vkoverIcon}
          alt='vkover-icon'
          sx={{
            width: "60px",
            height: "60px",
          }}
        />
        <Typography
          variant={"h5"}
          component={"h3"}
          flexGrow={1}
          color='#2D2C5F'
          fontWeight={600}
          fontSize={"30px"}
          maxWidth={"auto"}
          lineHeight={"1.2"}
          sx={{
            "@media (max-width: 768px)": {
              flexGrow: 0,
            },
          }}
        >
          Yella Advantage
        </Typography>
        {/* <Typography
          variant={"body1"}
          component={"p"}
          maxWidth={"400px"}
          fontSize={"15px"}
          lineHeight={2}
        >
          The visual form of a document or a typeface without relying on
          meaningful content.
        </Typography> */}
      </Box>
      <Grid container spacing={3}>
        <AdvantageCard
          logo={fast}
          title='Fast and Easy'
          description={
            "We’ve made plan selection easy for you. Your preferences are fed to our algorithms to compare every available plan against them and bring out only the best few to save your time and Money"
          }
        />
        <AdvantageCard
          logo={storage}
          title='Storage and Reminders'
          description={
            "Store and manage all policies at one place.Timely reminder service for all your insurance renewals."
          }
        />
        <AdvantageCard
          logo={support}
          title='Dedicated Customer Support'
          description={
            "Our dedicated support team is available for your assistance at all times.You can reach out to us while purchase of an insurance policy or assistance during the settlement of a claim."
          }
        />
      </Grid>
    </Container>
  );
};

export default AdvantagesSection;
