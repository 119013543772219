import React from "react";
import Hero from "../components/Hero";
import AdvantagesSection from "../components/AdvantagesSection/AdvantagesSection";
import ReviewsSection from "../components/ReviewsSection/ReviewsSection";
import POSPSection from "../components/POSP/POSPSection";
import Footer from "../components/Footer/Footer";
import Companies from "../components/Companies/Companies";
import { Helmet } from "react-helmet";
import GoogleTranslate from "../components/GoogleTranslate/GoogleTranslate";
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Yella Insurance - Compare, Buy / Renew Insurance Policy - Health,
          Life, Car & Bike{" "}
        </title>
        <meta name='viewport' content='width=device-width,user-scalable=no' />
        <meta
          name='description'
          itemprop='description'
          content='Yella Insurance - Buy health & vehicle insurance policy online. Compare car, bike & health insurance plans from top insurers.'
        ></meta>

        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-G7DG4TP8MQ'
        ></script>

        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'G-G7DG4TP8MQ')`}
        </script>
      </Helmet>
      <GoogleTranslate />
      <Hero />
      <AdvantagesSection />
      <ReviewsSection />
      <POSPSection />
      <Companies />
      <Footer />
    </>
  );
};

export default IndexPage;
