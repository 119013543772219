import React from "react";
import { Avatar, Box, Grid, Paper, Rating, Typography } from "@mui/material";
import { Container } from "@mui/system";
import invertedComma from "./../../images/inverted-commas.svg";
const ReviewCard = ({ review, name }) => {
  return (
    <>
      <Grid
        item
        md={4}
        sx={{
          "@media (max-width: 768px)": {
            minWidth: "280px",
            mr: "12px",
          },
        }}
      >
        <Paper
          sx={{
            py: 3,
            px: 4,
            height: "100%",
            "@media (max-width: 768px)": {
              width: "100%",
              py: 2,
              px: 3,
            },
          }}
        >
          {" "}
          <img src={invertedComma} />
          <Typography
            variant='body1'
            minHeight={"114px"}
            fontSize={"15px"}
            mt={2}
            mb={3}
          >
            {review}
          </Typography>
          <Box
            display={"flex"}
            sx={{
              flexWrap: "wrap",
              "@media (max-width: 768px)": {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
            alignItems={"center"}
            gap={"12px"}
          >
            <Avatar></Avatar>
            <Typography
              variant='body1'
              fontSize={"15px"}
              fontWeight='500'
              color={"#2D2C5F"}
            >
              {name}
            </Typography>
            <Rating readOnly value={5} />
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
const ReviewsSection = () => {
  return (
    <>
      <Container
        maxWidth='lg'
        sx={{
          mb: "100px",
          "@media (max-width: 768px)": {
            mb: "30px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "24px",
            mb: "60px",
            "@media (max-width: 768px)": {
              justifyContent: "center",
              textAlign: "center",
              mb: "30px",
            },
          }}
        >
          <Typography
            variant={"h5"}
            component={"h3"}
            flexGrow={1}
            color='#2D2C5F'
            fontWeight={600}
            fontSize={"30px"}
            lineHeight={"1.2"}
            sx={{
              "@media (max-width: 768px)": {
                maxWidth: "unset",
              },
            }}
          >
            We are not going to tell you we are best, but they are
          </Typography>
          {/* <Typography
            variant={"body1"}
            component={"p"}
            maxWidth={"400px"}
            fontSize={"15px"}
            lineHeight={2}
          >
            The visual form of a document or a typeface without relying on
            meaningful content.
          </Typography> */}
        </Box>
        <Grid
          container
          spacing={3}
          sx={{
            "@media (max-width: 768px)": {
              flexWrap: "nowrap",
              overflowX: "scroll",
            },
          }}
        >
          <ReviewCard
            name={"Mr. Prakash Balakrishnan"}
            review={
              "The process is quick and simple, there were no complications at all. I strongly recommend others using services offered by Yella"
            }
          />
          <ReviewCard
            name={"Thomas Palackal"}
            review={
              "We took an Employer Employee Life Insurance Plan from Yella,awesome experience,hats off to the speed and efficiency of Yella team"
            }
          />
          <ReviewCard
            name={"Rajesh Mathew"}
            review={
              "My  experience with Yella was an absolute delight. Their positive response and effective actions changed my opinion of the entire insurance segment. Hope they keep it up!"
            }
          />
        </Grid>
      </Container>
    </>
  );
};
export default ReviewsSection;
